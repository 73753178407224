<template>
  <div class="roles_page">
    <div class="roles_page_box">
      <!-- 左侧信息 -->
      <LeftRoleList
          titleName="角色列表"
          :list="roleList"
          @clickRole="getRoleInfo"
          ref="leftTreeRef"
          @addRole="addRole"  />
      <!-- 右侧主内容 -->
      <div class="roles_detail">
        <div class="roles_detail_title">
          <span style="margin-right: 10px;">角色详情信息</span>
          <div class="buttons-wrap">
            <a-button class="mr12 right"
                      type="primary"
                      @click="openDrawer('edit', '编辑')"
                      :disabled="roleDetail.status != '0'"
                      v-auth="['system:roleManagement:editRole']">
              编辑
            </a-button>
            <a-button class="mr12 right"
                      type="primary"
                      @click="openDrawer('copy', '复制')"
                      v-auth="['system:roleManagement:copy']">
              复制
            </a-button>
            <a-button class="mr12 right"
                      type="primary"
                      @click="checkRoleStatus('forbidden')"
                      v-show="roleDetail.status == '0'"
                      v-auth="['system:roleManagement:disable']">
              禁用
            </a-button>
            <a-button class="mr12 right"
                      type="primary"
                      @click="checkRoleStatus('active')"
                      v-show="roleDetail.status == '1'"
                      v-auth="['system:roleManagement:active']">
              启用
            </a-button>
            <a-button class="mr12 right deleteBtn"
                      @click="checkRoleStatus('delete')"
                      v-show="roleDetail.isSystemRole != '1'"
                      v-auth="['system:roleManagement:delete']">
              <DeleteOutlined />
              删除
            </a-button>
          </div>
        </div>
        <div class="info">
          <div class="info_top_box">
            <!-- 角色详细信息 -->
            <div class="info_detail" >
              <a-form
                  :model="roleDetail"
                  ref="formRef"
              >
                <a-row>
                  <a-col :span="8">
                    <a-form-item label="角色名称">
                      {{ roleDetail.roleName }}
                    </a-form-item>
                  </a-col>
                  <a-col :span="16">
                    <a-form-item label="角色描述">
                      {{ roleDetail.remark }}
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24">
                    <a-form-item label="角色互斥">
                      {{ roleDetail.mutexStr }}
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="8">
                    <a-form-item label="创建人">
                      {{ roleDetail.createOperator }}
                    </a-form-item>
                  </a-col>
                  <a-col :span="16">
                    <a-form-item label="创建时间">
                      {{ roleDetail.createTime }}
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="8">
                    <a-form-item label="更新人">
                      {{ roleDetail.operator }}
                    </a-form-item>
                  </a-col>
                  <a-col :span="16">
                    <a-form-item label="更新时间">
                      {{ roleDetail.operationTime }}
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </div>
          </div>
          <!-- 权限树 -->
          <div class="info_bottom_box" :class="{'edit_bottom_box': true}" id="perm-container" v-resize="computeHeight">
            <div class="info_permission">
              <PermissionTree
                  ref="permissionTreeRef"
                  :allFirstMenuList="allFirstMenuList"
                  :roleId="roleDetail.roleId"
                  :roleStatus="roleDetail.status"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增、复制、编辑角色弹窗 -->
    <CreateDrawer
        v-model:visible="drawerVisible"
        :title="drawerTitle"
        :initalData="initalData"
        :operationType="operationType"
        @handleSave="handleSave"
        @handleClose="handleClose" />
  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>

<script>
import LeftRoleList from '@/views/home/ConfigManagement/RoleManagement/components/LeftRoleList.vue'
import CreateDrawer from '@/views/home/ConfigManagement/RoleManagement/components/CreateDrawer.vue'
import PermissionTree from '@/views/home/ConfigManagement/RoleManagement/components/PermissionTree.vue'
import { onMounted, reactive, toRefs, ref } from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {message} from "ant-design-vue";
import {DeleteOutlined} from "@ant-design/icons-vue";
import ModalBox from '@/components/ModalBox'
export default {
  name: "index",
  components: {
    LeftRoleList,
    CreateDrawer,
    PermissionTree,
    DeleteOutlined,
    ModalBox
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const roleAllInfo = reactive({
      roleList: [],
      roleDetail: {},
      rolePermission: [],
      choosenFirstMenuList: [],
      allFirstMenuList: [],
    })
    const drawerVisible = ref(false)
    const drawerTitle = ref()
    const permissionTreeRef = ref()
    const getRoleList = async () => {
      const params = { status: 'all' }
      const { data } = await $api.getRoleList(params)
      roleAllInfo.roleList = data
      getRoleInfo()
    }

    // 点击角色列表
    const getRoleInfo = async (roleId) => {
      const params = { roleId: roleId ? roleId : roleAllInfo.roleList[0]?.roleId }
      await getRoleDetail(params)
      permissionTreeRef.value.updateMenuAndPerm()
      permissionTreeRef.value.cancelBtn()
    }
    // 获取角色详情
    const getRoleDetail = async (params) => {
      const { data } = await $api.getRoleDetailInfo(params)
      Object.assign(data.staffRole, { mutexList: data.mutexList, mutexStr: data.mutexStr })
      roleAllInfo.roleDetail = data.staffRole
    }

    const getAllFirstMenu = async () => {
      const params = { roleId: "" }
      const { data } = await $api.getFirstMenuPerms(params)
      roleAllInfo.allFirstMenuList = data
      console.log("all:",roleAllInfo.allFirstMenuList)
    }

    const operationType = ref('')
    const initalData = ref()

    // 新增 编辑 复制
    const openDrawer = (type, title) => {
      drawerTitle.value = `${title}角色`
      operationType.value = type
      initalData.value = roleAllInfo.roleDetail
      drawerVisible.value = true
    }

    const handleSave = async (formData) => {
      await $api.addNewRole(formData)
      message.success("保存成功")
      getRoleList()
      handleClose()
    }
    const handleClose = () => {
      drawerVisible.value = false
    }

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {type, msgType, params} = modalData.value
      updateRole(type, msgType, params)
    }
    // 删除 禁用
    const checkRoleStatus = async (type) => {
      const params = {
        roleId: roleAllInfo.roleDetail.roleId,
        type,
      }
      const { code } = await $api.checkRoleStaff(params)
      if(code == 200) {
        const msgType = {
          forbidden: '禁用',
          delete: '删除',
          active: '启用',
        }
        modelValue.value = true
        content.value = `确定${msgType[type]}该角色吗?`
        showIconCom.value = 'ExclamationCircleOutlined'
        modalData.value = {type, msgType, params}
        /*Modal.confirm({
          title: `确定${msgType[type]}该角色吗?`,
          icon: createVNode(ExclamationCircleOutlined),
          okText: "确定",
          cancelText: "取消",
          centered: true,
          onOk() {
            updateRole(type, msgType, params)
          },
        });*/
      }
    }
    const updateRole = async (type, msgType, params) => {
      const { code } = await $api.updateRole(params)
      if(code == 200) {
        message.success(`${msgType[type]}成功！`);
        getRoleList()
      }
    }

    const addRole = () => {
      openDrawer("add", "新增")
    }

    // 计算菜单的显示长度
    const computeHeight = () => {
      document.getElementById("menu-container").style.minHeight = ""
      const permContainerHeight = document.getElementById("perm-container").clientHeight
      const leftContainerHeight = permContainerHeight - 120
      document.getElementById("menu-container").style.minHeight = leftContainerHeight + 'px'
    }

    onMounted(() => {
      getRoleList()
      getAllFirstMenu()
    })
    return {
      ...toRefs(roleAllInfo),
      getRoleList,
      getRoleDetail,
      drawerVisible,
      handleSave,
      handleClose,
      openDrawer,
      drawerTitle,
      addRole,
      operationType,
      initalData,
      checkRoleStatus,
      permissionTreeRef,
      getRoleInfo,
      computeHeight,
      modelValue,
      content,
      showIconCom,
      handleOk,
    }
  }
}
</script>

<style lang="less" scoped>
.roles_page{
  height: 100%;
  width: 100%;

  .roles_page_box{
    height: 100%;
    display: flex;
    >div{
      height: 100%;
      background: #fff;
      border-radius: 4px;
    }
    .roles_detail{
      background: none;
      width: calc(100% - 251px);
      height: 100%;
      overflow: auto;
      padding-left: 21px;
      .roles_detail_title,
      .info_bottom_box_title {
        height: 85px;
        display: flex;
        align-items: center;
        padding: 0 21px;
        justify-content: space-between;
        border-bottom: 1px solid #E9E9E9;
        background: #fff;
        >span{
          font-size: 19px;
          font-weight: bold;
        }
        .operation-btns button{
          margin-left: 8px;
        }
      }
      .info{
        height: calc(100% - 85px);
        .info_top_box{
          background: #fff;
          min-height: 312px;
        }
        .info_bottom_box{
          margin-top: 16px;
          background: #fff;
          min-height: calc(100% - 333px);
          position: relative;
        }
        .info_detail {
          background: #fff;
          padding: 32px 43px;
          height: 100%;

          :deep(.ant-form-item),
          :deep(.ant-form-item-label) > label {
            font-size: 16px;
          }
        }
      }
      :deep(.permission-list) {
        width: 100%;
        height: calc(100% - 100px);
        //position: absolute;
      }
    }
  }
  :deep(.ant-form-horizontal .ant-form-item-control) {
    word-break: break-all;
  }
}
.deleteBtn{
  background-color: #dc5e4f;
  color: #fff;
  border-color: #dc5e4f;
}
.deleteBtn:hover{
  background-color:#EC4B38;
  border-color: #EC4B38;
}
</style>

<template>
  <div class="left_list">
    <div class="title">
      {{ titleName }}
    </div>
    <div class="roles_list">
      <div class="roles_main">
        <div class="role_item"
             :class="{'disable_role': i.status == '1', 'act_item': i.roleId == currentRoleId}"
             v-for="(i, index) in list" :key="index"
             @click="handleNodeClick(i)">
          {{i.roleName}}
        </div>
      </div>
      <div class="add_role" @click="addRole" v-auth="['system:roleManagement:add']">
        <plus-circle-outlined />
        &nbsp;新增
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { PlusCircleOutlined } from "@ant-design/icons-vue";
export default {
  name: "LeftRoleList",
  components: {
    PlusCircleOutlined
  },
  props: {
    titleName: String,
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  emits: ["click-role", "add-role"],
  setup(props, { emit }) {
    const currentRoleId = ref()
    watch(
        () => props.list,
        (val) => {
          if (val.length > 0) {
            currentRoleId.value = val[0].roleId
          }
        }
    );

    const handleNodeClick = (roleInfo) => {
      currentRoleId.value = roleInfo.roleId
      emit("click-role", currentRoleId.value)
    }
    const addRole = () => {
      emit("add-role")
    }
    return {
      currentRoleId,
      handleNodeClick,
      addRole,
    }
  }

}
</script>

<style scoped lang="less">
.left_list{
  height: 100%;
  background: #fff;
  border-radius: 4px;
  width: 251px;
  .title{
    height: 75px;
    padding-left: 15px;
    border-bottom: 1px solid #e9e9e9;
    line-height: 75px;
    //font-size: 16px;
    //font-weight: 700;
    font-size: 16px;
    font-weight: bold;
  }
  .roles_list{
    height: calc(100% - 75px) !important;
    padding: 10px 11px 21px;
    .roles_main{
      width: 100%;
      height: calc(100% - 137px);
      overflow-y: auto;
      overflow-x: scroll;
      .role_item{
        white-space: nowrap;
        display: table-caption;
        height: 35px;
        min-width: 229px;
        line-height: 35px;
        color: #000000;
        font-size: 16px;
        cursor: pointer;
        padding: 0 11px;
      }
      .disable_role{
        color: #999999 !important;
      }
      .act_item{
        background: #d0e8ff;
        //border-radius: 3px;
      }
    }
    .add_role{
      width: 208px;
      height: 48px;
      border: 1px solid #BFBFBF;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #666666;
      margin-left: 10px;
      margin-top: 48px;
      cursor: pointer;
      img{
        width: 21px;
        height: 21px;
        margin-right: 5px;
      }
    }
  }
}
.tree_main_bottom{
  height: 42px;
  width: 208px;
  border-radius: 5px;
  border: 1px solid #BFBFBF;
  margin: 4px auto 0;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  cursor: pointer;
}
</style>

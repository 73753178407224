<template>
  <div class="info_tree_title">
    <span>权限列表</span>
    <div class="title_right" v-if="treeEdit == 0">
      <a-button type="primary"
                @click="editBtn"
                :disabled="roleStatus != '0'"
                v-auth="['system:roleManagement:editPermissions']">
        编辑
      </a-button>
    </div>
    <div class="title_right" v-else>
      <a-button @click="cancelBtn">取消</a-button>
      <a-button type="primary" @click="savePermission">
        保存
      </a-button>
    </div>
  </div>
  <div class="permission-list">
    <div class="left-menu" id="menu-container">
      <div class="permission_main">
        <template v-if="treeEdit == 0">
          <div class="permission_item"
               :class="{'act_item': i.child == currentMenu.child}"
               v-for="i in choosenFirstMenuList" :key="i.id"
               @click="handleMenuClick(i)"> {{i.permName}} </div>
        </template>
        <template v-else>
          <div class="permission_item"
               :class="{'act_item': i.child == currentMenu.child}"
               v-for="i in allFirstMenuList" :key="i.id"
               @click="handleMenuClick(i)"> {{i.permName}} </div>
        </template>

      </div>
    </div>
    <div class="right-tree" id="right-container">
      <a-tree
          class="draggable-tree"
          block-node
          :tree-data="choosenPermissionTree"
          default-expand-all
          v-if="treeEdit == 0"
      />
      <div v-if="treeEdit == 1">
        <a-tree
            v-model:checkedKeys="checkedPermIds"
            checkable
            :tree-data="allPermissionTree"
            v-model:expandedKeys="expandedKeys"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, watch, toRefs } from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {message} from "ant-design-vue";
export default {
  name: "PermissionTree",
  props: {
    allFirstMenuList: {
      type: Array,
      default: () => {
        return []
      }
    },
    roleId: String,
    roleStatus: String,
  },
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    const expandedKeys = ref([]);
    const permDetail = reactive({
      treeEdit: 0, // 权限编辑 0-查看 1-编辑
      defaultId: [], // 默认选择的权限数据
      choosenPermissionTree: [], // 选择的权限树数据
      allPermissionTree: [], // 全部权限树
      checkedPermIds: [],
    })

    // 获取当前角色权限,当前菜单拥有的全部权限
    const getRolePerms = async (params) => {
      const { data } = await $api.getCurentPermList(params)
      permDetail.choosenPermissionTree = data && data.length > 0 ? data[0].children : []
    }

    const modifyingCheckedIds = ref({})

    const menuDataList = ref([])
    const editBtn = () => {
      permDetail.treeEdit = 1
    }

    const cancelBtn = () => {
      permDetail.treeEdit = 0
    }

    const currentMenu = ref({})

    const handleMenuClick = async (i) => {
      currentMenu.value = i
      updateRolePerm()
    }

    watch(
        () => permDetail.treeEdit,
        () => {
          updateMenuAndPerm()
          modifyingCheckedIds.value = {}
        },
    )

    const updateMenuAndPerm = async () => {
      await updateRoleMenu()
      await updateRolePerm()
    }

    const updateRolePerm = async () => {
      const params = {
        firstMenuId: currentMenu.value.child, //一级菜单id
        roleId: props.roleId //角色id
      }
      // 编辑
      if(permDetail.treeEdit == 1) {
        await getPermIdsAndTree(params)
      }else {
        getRolePerms(params)
      }
    }

    const getPermIdsAndTree = async (params) => {
      const result = await Promise.all([$api.getCurentPermIds(params),$api.getAllPermList(params)])
      // 获取当前菜单的选中权限id
      const curentPermIdList = result[0].data
      if(!modifyingCheckedIds.value[params.firstMenuId]) {
        modifyingCheckedIds.value[params.firstMenuId] = [...curentPermIdList]
      }
      permDetail.checkedPermIds = [...new Set(modifyingCheckedIds.value[params.firstMenuId])]

      // 获取该菜单下的权限树
      const allPermTreeData = result[1].data
      expandedKeys.value.splice(0)
      if(allPermTreeData?.length > 0 && allPermTreeData[0].children?.length > 0) {
        allPermTreeData[0].title = "全部"
        expandedKeys.value = [...[allPermTreeData[0].key]]
        permDetail.allPermissionTree = allPermTreeData
      }else {
        permDetail.allPermissionTree = []
      }
    }

    const updateRoleMenu = async () => {
      let a = currentMenu.value.child
      if(permDetail.treeEdit == 1) {
        menuDataList.value = props.allFirstMenuList
      }else {
        await getFirstMenuList({roleId: props.roleId})
        menuDataList.value = choosenFirstMenuList.value
      }
      if(!currentMenu.value.child || permDetail.treeEdit === 0){
        currentMenu.value = menuDataList.value.length > 0 ? menuDataList.value[0] : {}
      }else {
        currentMenu.value = menuDataList.value.length > 0 ? JSON.parse(JSON.stringify(menuDataList.value.filter(item => item.child === a)))[0] : {}
      }
    }

    const choosenFirstMenuList = ref([])

    // 获取角色菜单
    const getFirstMenuList = async (params) => {
      const { data } = await $api.getFirstMenuPerms(params)
      choosenFirstMenuList.value = data
    }

    watch(
        () => permDetail.checkedPermIds,
        (val) => {
          modifyingCheckedIds.value[currentMenu.value.child] = val
        }
    )

    const savePermission = async () => {
      const params = {
        // permList: getAllCheckedIds(modifyingCheckedIds.value),  //权限IdList
        permMap: modifyingCheckedIds.value,
        roleId: props.roleId, //角色Id
      }
      const { code } = await $api.saveRolePerms(params)
      if(code == 200) message.success("保存成功");
      cancelBtn()
    }

    onMounted(() => {
    })

    return {
      handleMenuClick,
      currentMenu,
      ...toRefs(permDetail),
      editBtn,
      cancelBtn,
      expandedKeys,
      savePermission,
      menuDataList,
      updateMenuAndPerm,
      choosenFirstMenuList,
      getFirstMenuList,
      modifyingCheckedIds,
    }
  }
}
</script>

<style scoped lang="less">
.info_tree_title{
  height: 85px;
  border-bottom: 1px solid #E9E9E9;
  display: flex;
  padding: 0 21px;
  align-items: center;
  justify-content: space-between;
  >span{
    font-size: 19px;
    color: #333333;
    font-weight: bold;
  }
  .title_right{
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 8px;
    >span{
      font-size: 14px;
      color: #666;
      margin-right: 16px;
      >span{
        color: #333;
      }
    }
    >button {
      margin-left: 8px;
    }
  }
}
.permission-list {
  display: flex;
  flex-direction: row;
  height: 100%;

  .left-menu {
    width: 15%;
    height: 100%;
    border-right: 1px solid #E9E9E9;
    padding: 20px 0 20px 0px;

    .permission_main {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: scroll;

      .permission_item {
        white-space: nowrap;
        height: 40px;
        line-height: 40px;
        color: #000000;
        font-size: 16px;
        cursor: pointer;
        padding: 0 11px;
        text-align: center;
      }

      .act_item {
        border-right: 2px solid #3a99f2;
        color: #3a99f2;
      }
    }
  }

  .right-tree {
    padding: 20px 0 20px 25px;

    :deep(.ant-tree) {
      font-size: 16px;
    }
  }

  .select-all {
     margin: 10px 0 0 10px;
  }
}
</style>

<template>
  <div ref="empInfoRef">
    <a-modal
        :getContainer="()=>$refs.empInfoRef"
        :visible="visible"
        @cancel="closeModal"
        width="40.6vw"
        :title="title"
        :cancelText="'取消'"
        :okText="'确定'"
        @ok="saveData"
        centered
    >
      <div class="drawer-form mt24">
        <a-form
            :model="formState"
            :rules="formRules"
            layout="vertical"
            class="form"
            ref="formRef"
        >
          <a-form-item
              label="角色名称"
              name="roleName"
              :colon="false"
          >
            <a-input
                class="form-item-inline"
                v-model:value="formState.roleName"
                placeholder="请输入"
                enter-button
                allowClear
                autocomplete="off"
                :maxlength="30"
            />
          </a-form-item>
          <a-form-item
              label="角色描述"
              name="remark"
              :colon="false"
          >
            <a-input
                class="form-item-inline"
                v-model:value="formState.remark"
                placeholder="请输入"
                enter-button
                allowClear
                autocomplete="off"
            />
          </a-form-item>
          <a-form-item
              label="角色互斥"
              name="mutexIdList"
              class="form-item-inline"
          >
            <SelectWithAll
                v-model:value="formState.mutexIdList"
                placeholder="请选择"
                :options="roleOptionsList"
                optionFilterProp="label"
                allowClear
            />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import { useGlobalPropertyHook } from "@/hooks/common";
import { reactive, ref, onMounted, watch } from "vue";
import SelectWithAll from "@/components/SelectWithAll";
import ModalBox from '@/components/ModalBox'
export default defineComponent({
  components: {SelectWithAll, ModalBox},
  props: {
    title: String,
    visible: Boolean,
    operationType: String,
    initalData: Object,
  },
  emits: ["handle-close", "handle-save"],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formState = reactive({
      roleId: "",
      copyRoleId: "",
      roleName: "",
      remark: "",
      mutexIdList: []
    })

    const formRules = {
      roleName: [
        {
          required: true,
          message: "请输入角色名称",
          trigger: "blur",
        },
        // {
        //   pattern: /^[a-zA-Z\u4e00-\u9fa5]+$/,
        //   message: "仅支持英文大小写及中文",
        //   trigger: "blur",
        // },
      ],
      remark: [
        {
          required: true,
          message: "请输入角色描述",
          trigger: "blur",
        },
      ],
    };

    const closeModal = () => {
      emit("handle-close")
    }
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      emit("handle-save", modalData.value)
    }
    const saveData = () => {
      formRef.value.validate().then(() => {
        if(props.operationType == 'copy') {
          formState.copyRoleId = formState.roleId
        }
        // 编辑时要确定原角色互斥关系是否被取消
        if(props.operationType == 'edit') {
          // 记录选中的互斥角色
          let newRoleObj = {}
          formState.mutexIdList.forEach(item => {
            newRoleObj[item] = true
          })
          // 和原始的互斥角色进行对比，找出被取消的原互斥角色
          let canceledRoleIds = []
          props.initalData.mutexList.forEach(item => {
            if(!newRoleObj[item.roleId]) {
              canceledRoleIds.push(item.roleName)
            }
          })
          if(canceledRoleIds.length > 0) {
            content.value = `${canceledRoleIds.map(item => item + '角色').join("与")}的互斥关系已取消，请确定是否保存？`
            showIconCom.value = 'ExclamationCircleOutlined'
            modalData.value = formState
            modelValue.value = true
            /*Modal.confirm({
              title: `${canceledRoleIds.map(item => item + '角色').join("与")}的互斥关系已取消，请确定是否保存？`,
              icon: createVNode(ExclamationCircleOutlined),
              width: "20%",
              okText: "确定",
              cancelText: "取消",
              centered: true,
              onOk() {
                emit("handle-save", formState)
              },
            });*/
          }else {
            emit("handle-save", formState)
          }
          console.log(canceledRoleIds)
        }else {
          emit("handle-save", formState)
        }

      });
    }
    const roleOptionsList = ref([])
    let optionsList = []
    const getRoleList = async () => {
      const params = {status: 'enable'}
      const {data} = await $api.getRoleList(params)
      optionsList = data.map(item => {
        return {
          ...item,
          label: item.roleName,
          value: item.roleId,
        }
      })
    }
    const formRef = ref()

    const resetForm = () => {
      Object.assign(formState, {
        roleId: "",
        copyRoleId: "",
        roleName: "",
        remark: "",
        mutexIdList: []
      })
      formRef.value.clearValidate();
    }

    watch(
        () => props.visible,
        async (val) => {
          if(val) {
            await getRoleList()
            if(props.operationType != 'add') {
              // 更改互斥角色字段名 字段格式
              const mutexIdList = []
              if(props.initalData.mutexList.length > 0) {
                props.initalData.mutexList.forEach(item => {
                  mutexIdList.push(item.roleId)
                })
              }
              Object.assign(formState, props.initalData, { mutexIdList })
              // 编辑 复制时过滤角色互斥下拉框 不显示当前编辑角色
              roleOptionsList.value = optionsList.filter(item => item.value != props.initalData.roleId)
            }else {
              roleOptionsList.value =optionsList
            }
          }else{
            resetForm();
          }
        }
    )
    onMounted(() => {
      getRoleList()
    })
    return {
      formState,
      roleOptionsList,
      closeModal,
      saveData,
      formRules,
      formRef,
      // mutexProxy,
      modelValue,
      content,
      showIconCom,
      handleOk,
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/style/aModal.less";
.drawer-operations {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .copy-tips {
    color: #ff0000;
  }

  button {
    margin-left: 8px;
  }
}

.drawer-form {
  .form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  :deep(.ant-form-item) {
    width: 48%;
  }
  .form-item-inline {

  }
}
</style>
